// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import AdminFormDetail from 'forms/Admin/AdminFormDetail'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'
import { ModalContext } from 'contexts/ModalContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'

export default function AdminViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params?.id === 'new'

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Usuari`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: `/admin/grid`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Usuari`,
    buttons: [
      {
        id: 'btnBack',
        name: <ImArrowLeft2 size={25} />,
        link: `/admin/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    Id: 0,
    Name: null,
    UserName: null,
    Password: null,
    Email: null
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `users/${params.id}`,
        method: 'GET',
        messageKo: 'Error al recoger datos de usuario',
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        Id: getApiCallGet.data.Id,
        Name: getApiCallGet.data.Name,
        Email: getApiCallGet.data.Email,
        UserName: getApiCallGet.data.UserName,
        NewPassword: null,
        RepeatNewPassword: null
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `users`
    let method = 'POST'
    values.Id = '0'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`

      if (values.NewPassword === '') {
        values.NewPassword = null
        values.RepeatNewPassword = null
      }
    }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageOk: 'Dades usuari guardades correctament',
      messageKo: 'Error al guardar les dades',
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation('/admin/grid')
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation('/admin/grid')
      }
    }
  }, [getApiCallPost.loading])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async (id) => {
    const apiCallDelete = {
      url: `users/${id}`,
      method: 'DELETE',
      messageOk: 'Usuari borrat correctament',
      messageKo: 'Error al borrar usuari les dades',
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation('/admin/grid')
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation('/admin/grid')
      }
    }
  }, [getApiCallDelete.loading])

  // ############################################################

  return (
    <>
      <div className="w-full h-full bg-white">
        <div className="w-full mb-3 p-4 print:py-0 print:m-0 bg-gray-200 border-2 border-indigo-800 rounded print:border-0">
          <AdminFormDetail
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            fields={fields}
            isNew={isNew}
          />
        </div>
      </div>
    </>
  )
}

AdminViewDetail.propTypes = {
  params: PropTypes.any
}
