import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { UserContext } from 'contexts/UserContext';
import logoExcel from 'assets/img/logoExcel.png';
import logoExcelRound from 'assets/img/Excel-2-icon.png';
import logoTxt from 'assets/img/logoTxt.png';
import flecha from 'assets/img/imageInvert.png';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';

export default function StudentsTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos,
  onSuccess,
  onSuccessPracts,
}) {
  const [file, setFile] = useState(null);
  const { user } = useContext(UserContext);
  const [schoolData, setSchoolData] = useState({});
    const [studentData, setStudentData] = useState([]);
  const [studentDataMap, setStudentDataMap] = useState({});
  const [selectedCodiCentre, setSelectedCodiCentre] = useState('');
  const rowsEmpty = datos.length === 0;

  useEffect(() => {
    // Fetch school data
    const fetchSchoolData = async () => {
      try {
        const response = await fetch( process.env.REACT_APP_API_URL + '/schools/');
        const data = await response.json();
        const schoolDataMap = data.reduce((acc, school) => {
          acc[school.CODI] = school.CENTRE;
          return acc;
        }, {});
        setSchoolData(schoolDataMap);
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await fetch( process.env.REACT_APP_API_URL + '/students/all');
        const data = await response.json();

        
        setStudentData(data);
        const studentDataMap = data.reduce((acc, student) => {
          acc[student.CODI_BECARI] = {
            numSS: student.NUM_SS,
            fechaNacimiento: student.DATA_NAIXAMENT,
            fechaInicio: student.DATA_INICI,
            fechaFin: student.DATA_FINALITZACIO,
            checkInici: student.CHECK_TASCA_INICI,
            checkFinal: student.CHECK_TASCA_FINAL,
            obs: student.OBSERVACIONS_ALUMNE || '',
            gener: student.January,
            febrer: student.February,
            març: student.March,
            abril: student.April,
            maig: student.May,
            juny: student.June,
            juliol: student.July,
            agost: student.August,
            setembre: student.September,
            octubre: student.October,
            novembre: student.November,
            desembre: student.December
          };
          return acc;
        }, {});
        
        setStudentDataMap(studentDataMap);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchSchoolData();
    fetchStudentData();
  }, []);

  // const exportToExcel = () => {
  // // Mapeo de los datos para incluir todos los campos del formulario
  //   const formattedData = datos.map(student => {
  //     const studentInfo = studentDataMap[student.DNI_NIE_PASSAPORT] || {}; // Acceder a la información adicional desde el studentDataMap
      
  //     // Formatear las fechas en formato día/mes/año
  //     const formattedFechaNacimiento = studentInfo.fechaNacimiento ? format(new Date(studentInfo.fechaNacimiento), 'dd-MM-yyyy') : '';
  //     const formattedDataInici = student.DATA_INICI ? format(new Date(student.DATA_INICI), 'dd-MM-yyyy') : '';
  //     const formattedDataFinalitzacio = student.DATA_FINALITZACIO ? format(new Date(student.DATA_FINALITZACIO), 'dd-MM-yyyy') : '';


  //     return {
  //       "Codi Becari": student.CODI_BECARI,
  //       "Codi Centre": student.CODI_CENTRE,
  //       "Cognoms Nom": student.COGNOMS_NOM,
  //       "DNI": student.DNI_NIE_PASSAPORT,
  //       "Numero Seguretat Social": studentInfo.numSS,
  //       "Data Naixament": formattedFechaNacimiento,
  //       "Inici Pràctiques": formattedDataInici,
  //       "Fi Pràctiques": formattedDataFinalitzacio,
  //       "Check Tasca Inici": student.CHECK_TASCA_INICI,
  //       "Check Tasca Final": student.CHECK_TASCA_FINAL
  //     };
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(formattedData);
  //   const workbook = {
  //     Sheets: { 'Students': worksheet },
  //     SheetNames: ['Students']
  //   };
    
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(data, 'students.xlsx');
  // };

  const exportToExcel = async () => {
  // Asegurarse de que 'datos' sea un array
  console.log("Datos en exportToExcel:", datos); // Verifica si OBSERVACIONS_ALUMNE está presente


  const formattedDatos = Array.isArray(datos) ? datos : []; // Asegura que datos sea un array
  
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Students');

  console.log("Contenido de datos:", formattedDatos);

  worksheet.columns = [
    { header: 'Codi Becari', key: 'CodiBecari', width: 15 },
    { header: 'Codi Centre', key: 'CodiCentre', width: 15 },
    { header: 'Cognoms Nom', key: 'CognomsNom', width: 50 },
    { header: 'DNI', key: 'DNI', width: 15 },
    { header: 'Numero Seguretat Social', key: 'NumSS', width: 25 },
    { header: 'Data Naixement', key: 'DataNaixament', width: 15 },
    { header: 'Inici Pràctiques', key: 'DataInici', width: 15 },
    { header: 'Fi Pràctiques', key: 'DataFinalitzacio', width: 15 },
    { header: 'Check Inici', key: 'CheckTascaInici', width: 15 },
    { header: 'Check Final', key: 'CheckTascaFinal', width: 15 },
    { header: 'Observacions', key: 'Observacions', width: 30 },
    { header: 'Gener', key: 'Gener', width: 10 },
    { header: 'Febrer', key: 'Febrer', width: 10 },
    { header: 'Març', key: 'Març', width: 10 },
    { header: 'Abril', key: 'Abril', width: 10 },
    { header: 'Maig', key: 'Maig', width: 10 },
    { header: 'Juny', key: 'Juny', width: 10 },
    { header: 'Juliol', key: 'Juliol', width: 10 },
    { header: 'Agost', key: 'Agost', width: 10 },
    { header: 'Setembre', key: 'Setembre', width: 10 },
    { header: 'Octubre', key: 'Octubre', width: 10 },
    { header: 'Novembre', key: 'Novembre', width: 10 },
    { header: 'Desembre', key: 'Desembre', width: 10 }
  ];

  // Estilo de cabecera
  worksheet.getRow(1).eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFCC00' }
    };
    cell.font = { bold: true, color: { argb: 'FF0000FF' } };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });
  worksheet.getRow(1).height = 25; // Establece la altura de la cabecera a 25

  // Mapeo de datos formateados
  const formattedData = formattedDatos.map(student => {
    const studentInfo = studentDataMap[student.CODI_BECARI] || {};
    const formattedFechaNacimiento = studentInfo.fechaNacimiento ? format(new Date(studentInfo.fechaNacimiento), 'dd-MM-yyyy') : '';
    const formattedDataInici = student.DATA_INICI ? format(new Date(student.DATA_INICI), 'dd-MM-yyyy') : '';
    const formattedDataFinalitzacio = student.DATA_FINALITZACIO ? format(new Date(student.DATA_FINALITZACIO), 'dd-MM-yyyy') : '';

    return {
      CodiBecari: student.CODI_BECARI,
      CodiCentre: student.CODI_CENTRE,
      CognomsNom: student.COGNOMS_NOM,
      DNI: student.DNI_NIE_PASSAPORT,
      NumSS: studentInfo.numSS,
      DataNaixament: formattedFechaNacimiento,
      DataInici: formattedDataInici,
      DataFinalitzacio: formattedDataFinalitzacio,
      CheckTascaInici: student.CHECK_TASCA_INICI,
      CheckTascaFinal: student.CHECK_TASCA_FINAL,
      Observacions: studentInfo.obs || '',
      Gener: studentInfo.gener || 0,
      Febrer: studentInfo.febrer || 0,
      Març: studentInfo.març || 0,
      Abril: studentInfo.abril || 0,
      Maig: studentInfo.maig || 0,
      Juny: studentInfo.juny || 0,
      Juliol: studentInfo.juliol || 0,
      Agost: studentInfo.agost || 0,
      Setembre: studentInfo.setembre || 0,
      Octubre: studentInfo.octubre || 0,
      Novembre: studentInfo.novembre || 0,
      Desembre: studentInfo.desembre || 0
    };
  });

  // formattedData.forEach((data) => {
  //   worksheet.addRow(data);
  // });

    formattedData.forEach((data, index) => {
    const row = worksheet.addRow(data);
    row.height = 25; // Establece la altura de cada fila a 25
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blobData = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  saveAs(blobData, 'students.xlsx');
};




  const exportToText = () => {
    let textData = '';
    // const filteredData = datos;
    const filteredData = Array.isArray(datos) ? datos : [];
    filteredData.forEach(student => {
      const tipoRegistro = '01'; // Tipo de registro: '01'
      const codigoEmpresa = (student.CODI_CENTRE || '').padStart(5, '0'); // Código de empresa
      const codigoTrabajo = (schoolData[student.CODI_CENTRE] || '').padStart(5, '0'); // Código de trabajo (centro)
      const codigoTrabajador = (student.CODI_BECARI || '').padStart(6, '0'); // Código de trabajador
      const studentInfo = studentDataMap[student.CODI_BECARI] || {};
      const dni = (student.DNI_NIE_PASSAPORT || '').padEnd(9, ' '); // DNI
      // const apellidosNombre = (student.COGNOMS_NOM || '').padEnd(30, ' '); // Apellidos y nombre
      const apellidosNombre = (student.COGNOMS_NOM || '')
    .replace(',', '') // Elimina la coma
    .split(' ') // Divide la cadena en palabras
    .filter(word => word) // Filtra elementos vacíos (por si hay más de un espacio)
    .join(' ') // Vuelve a unir las palabras con un espacio entre ellas
    .toUpperCase() // Convierte todo a mayúsculas
    .padEnd(30, ' '); // Rellena con espacios hasta 30 caracteres
      const numMatricula = '00000'; // Numero matricula
      // const numSS = (studentDataMap[student.CODI_BECARI] || '').padStart(12, '0'); // Número de Seguridad Social, ajustado a 12 caracteres con ceros a la izquierda
      const numSS = (studentInfo.numSS || '').padStart(12, '0');
      const padre = '.'.padEnd(15, ' ');
      const madre = '.'.padEnd(15, ' ');
      const hscl = 'HSCL'.padEnd(33, ' ');
      const numEscaleraPisoPuerta = '0000';
      const municipio = 'BARCELONA'.padEnd(20, ' ');
      const cp = '08000'.padEnd(15, '0');
      const clavePercepcion = 'A'.padEnd(8, '0');
      const tipoRetencion = 'A'.padEnd(21, '0');
      const codigoSerie = ''.padEnd(16, ' ');
      const becari = 'BECARI'.padStart(9, '0');
      const becari0 = 'BECARI'.padStart(10, ' ');
      const grupoTarifa = '0711301MNN'.padStart(12, ' ');
      const fechaNacimiento =  format(new Date(studentInfo.fechaNacimiento), 'yyyyMMdd').padStart(9, ' ');
      const fechaInicio =  format(new Date(studentInfo.fechaInicio), 'yyyyMMdd');
      const indicadorPluriempleado = 'N'.padEnd(31, '0');
      const indicadorTiempoParcial = 'D000000000000000N000000000000000000000000000000000000000000000000000000000000000000'.padStart(123, ' ');
      const horasAndTipoContrato = '000100'.padStart(36, ' ');
      const fechaFin =  format(new Date(studentInfo.fechaFin), 'yyyyMMdd');
      const nivelEstudiosTerminados = '53'.padEnd(11, ' ');
      const numContrato = '00000000000190001000000000000019000100 0000000000000000190001001900010000000000000000';
      const datosPagasExtras = '000000000'.padStart(14, ' ');
      const finalDescuadrado = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'.padStart(197, ' ');
      const finalDescuadrado1 = '00000000000000000072400000000000000000000000000000000000000000000000000000000    00   00  000000000000    0100'.padStart(410, ' ');
      const finalPredeterminado = '00000000000000                                          0801972400000000000003724                                                                                                                                                                                                                                                                   '.padStart(453, ' ');


      textData += `${tipoRegistro}${codigoEmpresa}${codigoTrabajo}${codigoTrabajador}${dni}${apellidosNombre}${numMatricula}${numSS}${padre}${madre}${hscl}${numEscaleraPisoPuerta}${municipio}${municipio}${cp}${clavePercepcion}${tipoRetencion}${codigoSerie}${becari}${becari0}${grupoTarifa}${fechaNacimiento}${fechaInicio}${numEscaleraPisoPuerta}${numEscaleraPisoPuerta}${fechaInicio}${fechaInicio}${indicadorPluriempleado}${indicadorTiempoParcial}${horasAndTipoContrato}${fechaInicio}${fechaFin}${nivelEstudiosTerminados}${numContrato}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${finalDescuadrado}${finalDescuadrado1}${finalPredeterminado}\n`;
    });

    const textBlob = new Blob([textData], { type: 'text/plain' });
    saveAs(textBlob, 'students.txt');
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    readExcelFile(selectedFile);
  };


  const readExcelFile = (file) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const arrayBuffer = event.target.result;
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Convertir la hoja a JSON con cellDates activado para reconocer fechas
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellDates: true }); 

     // Validar que el archivo contenga las columnas requeridas
    const requiredColumns = [
      'DNI NIE PASS',
      // 'COGNOMS NOM',
      'COGNOM COGNOM NOM',
      'NÚMERO SS',
      'NÚMERO SS_1',
      'NÚMERO SS_2',
      'DATA NAIXEMENT',
      'INICI PRACTIQUES',
      'FI PRACTIQUES'
      // 'NÚMERO SS',
      // 'NÚMERO SS_1',
      // 'NÚMERO SS_2'
    ];
    const headers = Object.keys(jsonData[0] || {});
    const missingColumns = requiredColumns.filter(col => !headers.includes(col));

     if (missingColumns.length > 0) {
      // alert(`El fitxer no és vàlid`);
      toast.error(`El fitxer no és vàlid`);
      return;
    }

    // Formatear cada estudiante
    const formattedStudents = jsonData.map(student => {
      // Ajustar para coincidir con los nombres exactos de las columnas en el archivo Excel
      const ssPart1 = student['NÚMERO SS'] ? String(student['NÚMERO SS']).padStart(2, '0') : '00';
      const ssPart2 = student['NÚMERO SS_1'] ? String(student['NÚMERO SS_1']).padStart(8, '0') : '00000000';
      const ssPart3 = student['NÚMERO SS_2'] ? String(student['NÚMERO SS_2']).padStart(2, '0') : '00';

      // Concatenar las partes de la SS
      // student.SS = `${ssPart1} ${ssPart2} ${ssPart3}`.trim();
      
      student.SS = `${ssPart1}${ssPart2}${ssPart3}`.replace(/\s+/g, '');

      // Convertir fechas a formato ISO
      student['DATA NAIXEMENT'] = formatDate(student['DATA NAIXEMENT']);
      student['INICI PRACTIQUES'] = formatDate(student['INICI PRACTIQUES']);
      student['FI PRACTIQUES'] = formatDate(student['FI PRACTIQUES']);

      // Eliminar partes individuales de SS si no se necesitan
      delete student['NÚMERO SS'];
      delete student['NÚMERO SS_1'];
      delete student['NÚMERO SS_2'];
      
      return student;
    });


    // Subir estudiantes formateados
    uploadStudents(formattedStudents);
  };
  reader.readAsArrayBuffer(file);
};

// const formatDate = (date) => {
//   if (typeof date === 'string' && date.includes('/')) {
//     // Forzar el formato dd/mm/yyyy
//     // const [month, day, year] = date.split('/'); // ASI FUNCIONA CON EXCEL 2010 Y EXCEL 2013  APLICACION
//     const [day, month, year] = date.split('/'); // ASI FUNCIONA CON EL LIBRE OFFICE CALC Y SHAREPOINT
//     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
//   } else if (date instanceof Date) {
//     // Si es un objeto Date, formatearlo a yyyy-mm-dd
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
//   }
//   return date; // Si el valor no es reconocible, devolver como está
// };

const formatDate = (date) => {
  if (typeof date === 'string' && date.includes('/')) {
    const parts = date.split('/');
    if (parts.length === 3) {
      let day, month, year;
      if (parseInt(parts[0], 10) > 12) {
        // dd/mm/yyyy
        [day, month, year] = parts;
      } else {
        // mm/dd/yyyy
        [month, day, year] = parts;
      }
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  } else if (date instanceof Date) {
    // Si es un objeto Date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return date; // Si el valor no es reconocible, devolver como está
};





const handleFileUploadPracts = (event) => {
  const selectedFile = event.target.files[0];
  setFile(selectedFile);
  readExcelFilePracts(selectedFile);
};

const readExcelFilePracts = (file) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const arrayBuffer = event.target.result;
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
   //  const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: 0 });
// Validar columnes requerides
    const requiredColumns = [
      'CODI BECARI',
      'DNI NIE PASS',
      'NOM',
      'INICI PRACTIQUES',
      'FI PRACTIQUES',
      'GENER',
      'FEBRER',
      'MARÇ',
      'ABRIL',
      'MAIG',
      'JUNY',
      'JULIOL',
      'AGOST',
      'SETEMBRE',
      'OCTUBRE',
      'NOVEMBRE',
      'DESEMBRE'
    ];
    const headers = Object.keys(jsonData[0] || {});
    const missingColumns = requiredColumns.filter(col => !headers.includes(col));

    if (missingColumns.length > 0) {
      toast.error(`El fitxer no és vàlid`);
      return;
    }


    console.log(jsonData);
    uploadPractices(jsonData);
  };
  reader.readAsArrayBuffer(file);
};

const uploadStudents = async (students) => {
  try {
    const formattedStudents = students.map(student => ({
      // NOM: student.NOM,
      // COGNOM: student.COGNOMS,
      // NOM: student["COGNOMS NOM"],
      NOM: student["COGNOM COGNOM NOM"],
      DNI: student["DNI NIE PASS"],
      DATA_NAIXAMENT: student['DATA NAIXEMENT'],
      NUM_SS: student.SS, // Usamos el valor concatenado de la SS
      INICI_PRACTIQUES: student['INICI PRACTIQUES'],
      FI_PRACTIQUES: student['FI PRACTIQUES']
    }));

    const url = user.role_id === 1
      ? `${process.env.REACT_APP_API_URL}/import/massImport/${user.id}`
      : `${process.env.REACT_APP_API_URL}/import/massImport`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ students: formattedStudents }),
    });

    const result = await response.json();

    if (response.ok) {
      const { successCount, errorDetails } = result;
      onSuccess(); // Llama a la función de éxito para actualizar la tabla
      toast.success(`¡${successCount} alumnes importats amb exit!`);
      
      if (errorDetails.length > 0) {
        errorDetails.forEach(detail => {
          toast.error(`Error al importar el alumno ${detail.student.NOM}: ${detail.error}`);
        });
      }
    } else {
      toast.error('Error al importar los alumnos');
    }
  } catch (error) {
    console.error('Error importing students:', error);
  }
};

// const readExcelFile = (file) => {
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const arrayBuffer = event.target.result;
//     const workbook = XLSX.read(arrayBuffer, { type: 'array' });
//     const sheetName = workbook.SheetNames[0];
//     const worksheet = workbook.Sheets[sheetName];

//     // Convertir la hoja a JSON
//     const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellDates: true }); 

//     // Concatenar la SS
//     const formattedStudents = jsonData.map(student => {
//       // Concatenamos las tres columnas en el formato necesario
//       student.SS = `${student['00']} ${student['00000000']} ${student['11']}`.trim(); 
      
//       // Opcional: Eliminar las columnas si no se necesitan más
//       delete student['00'];
//       delete student['00000000'];
//       delete student['11'];
      
//       return student;
//     });

//     // Hacer POST con los estudiantes y el SS concatenado
//       uploadStudents(formattedStudents);
//       };
//       reader.readAsArrayBuffer(file);
//   };

//     const handleFileUploadPracts = (event) => {
//       const selectedFile = event.target.files[0];
//       setFile(selectedFile);
//       readExcelFilePracts(selectedFile);
//     };

//   const readExcelFilePracts = (file) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       const arrayBuffer = event.target.result;
//       const workbook = XLSX.read(arrayBuffer, { type: 'array' });
//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];
//       const jsonData = XLSX.utils.sheet_to_json(worksheet);
//       console.log(jsonData);
//       uploadPractices(jsonData);
//     };
//     reader.readAsArrayBuffer(file);
//   };

 
//   const uploadStudents = async (students) => {
//   try {
//       const formattedStudents = students.map(student => {
//     return {
//       NOM: student.NOM,  // Notación de punto
//       COGNOM: student.COGNOMS,  // Notación de punto
//       DNI: student["DNI NIE PASS"],  // Aquí usamos notación de corchetes porque hay espacios
//       DATA_NAIXAMENT: student['DATA NAIXAMENT'],  // Notación de corchetes porque hay espacios
//       NUM_SS: student.SS,  // Usamos el valor concatenado de la SS
//       INICI_PRACTIQUES: student['INICI PRACTIQUES'],  // Notación de corchetes por espacios
//       FI_PRACTIQUES: student['FI PRACTIQUES']  // Notación de corchetes por espacios
//     };
//   });

//     // const url = user.role_id === 1 ? `https://api-codina-af1a88eea621.herokuapp.com/import/massImport/${user.id}` : 'https://api-codina-af1a88eea621.herokuapp.com/import/massImport';
//     // const url = user.role_id === 1 ? `http://localhost:5000/import/massImport/${user.id}` : 'http://localhost:5000/import/massImport';
//     const url = user.role_id === 1 ? process.env.REACT_APP_API_URL + `/import/massImport/${user.id}` : process.env.REACT_APP_API_URL + '/import/massImport';
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       // body: JSON.stringify({ students }),
//       body: JSON.stringify({ students: formattedStudents }),
//     });

//     const result = await response.json();

//     if (response.ok) {
//       const { successCount, errorDetails } = result;
//       onSuccess(); // Llama a la función de éxito para actualizar la tabla
//       toast.success(`¡${successCount} alumnes importats amb exit!`);
      
//       if (errorDetails.length > 0) {
//         errorDetails.forEach(detail => {
//           toast.error(`Error al importar el alumno ${detail.student.NOM} ${detail.student.COGNOM}: ${detail.error}`);
//         });
//       }

      
//     } else {
//       toast.error('Error al importar los alumnos');
//     }
//   } catch (error) {
//     console.error('Error importing students:', error);
//     // toast.error('Error al importar');
//   }
// };

const uploadPractices = async (practices) => {
  try {
    const formattedPractices = practices.map(practice => {
      return {
        CODI_BECARI: practice["CODI BECARI"],
        GENER: practice.GENER,
        FEBRER: practice.FEBRER,
        MARÇ: practice.MARÇ,
        ABRIL: practice.ABRIL,
        MAIG: practice.MAIG,
        JUNY: practice.JUNY,
        JULIOL: practice.JULIOL,
        AGOST: practice.AGOST,
        SETEMBRE: practice.SETEMBRE,
        OCTUBRE: practice.OCTUBRE,
        NOVEMBRE: practice.NOVEMBRE,
        DESEMBRE: practice.DESEMBRE
      };
    });

    const url = user.role_id === 1
      ? process.env.REACT_APP_API_URL + `/import/practices/${user.id}`
      : process.env.REACT_APP_API_URL + '/import/practices';

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ practices: formattedPractices }),
    });

    const result = await response.json();

    if (response.ok) {
      const { successCount, errorDetails } = result;
      onSuccessPracts();
      toast.success(`${successCount} pràctiques importades correctament!`);
      errorDetails.forEach(detail => {
        toast.error(`Error al importar la pràctica de ${detail.practice.CODI_BECARI}: ${detail.error}`);
      });
    } else {
      toast.error('Error al importar les pràctiques');
    }
  } catch (error) {
    console.error('Error importing practices:', error);
  }
};


const downloadTemplate = async () => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Plantilla Alumnes');

    // Definir columnas con encabezados, claves y ancho
    worksheet.columns = [
      { header: 'DNI NIE PASS', key: 'DNI NIE PASS', width: 20 },
      // { header: 'COGNOMS', key: 'COGNOMS', width: 30 },
      // { header: 'NOM', key: 'NOM', width: 30 },
      // { header: 'COGNOMS NOM', key: 'COGNOMS NOM', width: 45 },
      { header: 'COGNOM COGNOM NOM', key: 'COGNOMS NOM', width: 45 },
      { header: 'NÚMERO SS', key: 'ss_part1', width: 20 },
      { header: 'NÚMERO SS', key: 'ss_part2', width: 20 },
      { header: 'NÚMERO SS', key: 'ss_part3', width: 20 },
      { header: 'DATA NAIXEMENT', key: 'DATA NAIXEMENT', width: 20 },
      { header: 'INICI PRACTIQUES', key: 'INICI PRACTIQUES', width: 20 },
      { header: 'FI PRACTIQUES', key: 'FI PRACTIQUES', width: 20 }
      // { header: 'NÚMERO SS', key: 'ss_part1', width: 20 },
      // { header: 'NÚMERO SS', key: 'ss_part2', width: 20 },
      // { header: 'NÚMERO SS', key: 'ss_part3', width: 20 }
    ];

    worksheet.eachRow((row) => {
      row.height = 25; // Altura específica para todas las filas
    });

    // Estilos para la cabecera
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC00' }
      };
      cell.font = { bold: true, color: { argb: 'FF0000FF' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    const numRows = 100; // Aplica validaciones hasta la fila 100, puedes ajustar según necesidad

    const namedni = ['A'];
    namedni.forEach((col) => {
      for (let i = 2; i <= numRows; i++) {
        const cell = worksheet.getCell(`${col}${i}`);

        // Configurar la validación para solo letras
        cell.dataValidation = {
        type: 'textLength',
        operator: 'equal',
        formulae: [9],
        showErrorMessage: true,
        errorTitle: 'Valor inválido',
        error: 'Debe ser un DNI, NIE o pasaporte válido de 9 caracteres.'
        };
        // cell.numFmt = '@';

        // Opcional: Agrega una nota para guiar al usuario
        cell.note = 'En caso de DNI: 12345678Z || En caso de NIE: X1234567L || En caso de PASSAPORTE: E12345678';
      }
    });

  const nameColumns = ['B'];
    nameColumns.forEach((col) => {
      for (let i = 2; i <= numRows; i++) {
        const cell = worksheet.getCell(`${col}${i}`);

        // Configurar la validación para solo letras
        cell.dataValidation = {
          // type: 'custom',
          // formulae: `ISTEXT(${col}${i})`, // Verifica que el valor sea texto
          allowBlank: false, // No permite celdas en blanco
          showErrorMessage: true,
          errorTitle: 'Valor inválido',
          error: 'Solo se permiten letras en este campo.'
        };
        cell.numFmt = '@';

        // Opcional: Agrega una nota para guiar al usuario
        cell.note = 'Por favor, ingresa apellidos y nombre en ese orden';
      }
  });

    // Validación para "DATA NAIXAMENT", "INICI PRACTIQUES" y "FI PRACTIQUES" - formato de fecha
    const dateColumns = ['F', 'G', 'H'];
    dateColumns.forEach((col) => {
      for (let i = 2; i <= numRows; i++) {
        const cell = worksheet.getCell(`${col}${i}`);

        // Aplicar validación de fecha estricta
        cell.dataValidation = {
          type: 'date',
          operator: 'greaterThan',
          formulae: [new Date(1901, 0, 1)],  // Fecha mínima permitida
          showErrorMessage: true,
          errorTitle: 'Fecha inválida',
          error: 'Por favor, ingresa una fecha válida después del 01/01/2016 en formato dd/mm/aaaa.'
        };
        cell.note = 'Por favor, ingresa la fecha en formato dd/mm/aaaa';
      }
    });

    for (let i = 2; i <= numRows; i++) {
  // Validación y nota para columna G
      const cellG = worksheet.getCell(`C${i}`);
      cellG.dataValidation = {
        type: 'whole',
        operator: 'between',
        formulae: [0, 99],
        showErrorMessage: true,
        errorTitle: 'Valor inválido',
        error: 'Ingresa un número entre 00 y 99 en formato de dos dígitos.'
      };
      cellG.note = 'Por favor, ingresa los 2 primeros digitos del Número de la Seguridad Social';

      // Validación y nota para columna H
      const cellH = worksheet.getCell(`D${i}`);
      cellH.dataValidation = {
        type: 'whole',
        operator: 'between',
        formulae: [0, 99999999],
        showErrorMessage: true,
        errorTitle: 'Valor inválido',
        error: 'Ingresa un número en formato de ocho dígitos.'
      };
      cellH.note = 'Por favor, ingresa los 8 digitos siguientes del Número de la Seguridad Social';
      
      // Validación y nota para columna I
      const cellI = worksheet.getCell(`E${i}`);
      cellI.dataValidation = {
        type: 'whole',
        operator: 'between',
        formulae: [0, 99],
        showErrorMessage: true,
        errorTitle: 'Valor inválido',
        error: 'Ingresa un número entre 00 y 99 en formato de dos dígitos.'
      };
      cellI.note = 'Por favor, ingresa los 2 últimos digitos del Número de la Seguridad Social';
    }

    // Configuración de formato para cada columna
    worksheet.getColumn('ss_part3').numFmt = '00';
    worksheet.getColumn('ss_part2').numFmt = '00000000';
    worksheet.getColumn('ss_part1').numFmt = '00';

    // Validación para Seguridad Social: longitud exacta
    // for (let i = 2; i <= numRows; i++) {
    //   worksheet.getCell(`G${i}`).dataValidation = {
    //     type: 'whole',
    //     operator: 'between',
    //     formulae: [0, 99],
    //     showErrorMessage: true,
    //     errorTitle: 'Valor inválido',
    //     error: 'Ingresa un número entre 00 y 99 en formato de dos dígitos.'
    //   };
    //   worksheet.getCell(`H${i}`).dataValidation = {
    //     type: 'whole',
    //     operator: 'between',
    //     formulae: [0, 99999999],
    //     showErrorMessage: true,
    //     errorTitle: 'Valor inválido',
    //     error: 'Ingresa un número entre 00 y 99 en formato de dos dígitos.'
    //   };
    //   worksheet.getCell(`I${i}`).dataValidation = {
    //     type: 'whole',
    //     operator: 'between',
    //     formulae: [0, 99],
    //     showErrorMessage: true,
    //     errorTitle: 'Valor inválido',
    //     error: 'Ingresa un número entre 00 y 99 en formato de dos dígitos.'
    //   };

    //   worksheet.getColumn('ss_part3').numFmt = '00';
    //   worksheet.getColumn('ss_part2').numFmt = '00000000';
    //   worksheet.getColumn('ss_part1').numFmt = '00';

    // }

    // Generar el archivo Excel y descargarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blobData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blobData, 'Plantilla_Alumnes.xlsx');
  } catch (error) {
    console.error('Error al descargar la plantilla:', error);
  }
};




  return (
    <div className="grid grid-cols-12 pr-2 text-center bg-white border-t-2 border-indigo-800 print:hidden">
      {user.role_id === 2 && (
      <div className="flex items-center justify-start col-span-4">
        <button
          onClick={exportToExcel}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '85px', marginRight: '5px', height: '85px' }}
        >
          <img
            src={logoExcel}
            alt="icon"
          />
        </button>
        <button
          onClick={exportToText}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '85px', marginRight: '5px', height: '85px' }}
        >
          <span
              title="EXPORTAR TXT PER A3"
              className="w-full flex flex-row items-center"
            >
            <img
              src={logoTxt}
              alt="icon"
              style={{ width: '70%' }} 
            />
          </span>
        </button>
      </div>
      )}
      {user.role_id === 1 && (
      <div className="flex items-center justify-center col-span-3">
        <div className="contents">
          <button
            className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
            style={{ width: '85px', marginRight: '-60px', height: '85px', zIndex: 2 }}
          >
            <img
              src={logoExcelRound}
              alt="icon"
              style={{ width: '65px', height: '65px' }} // Ajusta el tamaño según sea necesario
              onClick={() => downloadTemplate('students')}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-[#308446] hover:shadow-lg hover:scale-105"
            onClick={() => downloadTemplate('students')}
            style={{ paddingLeft: '45px' }} // Ajusta el espacio a la izquierda para acomodar la imagen
          >
            Descarregar Plantilla Alumnes
          </label>
        </div>
        {/* <button
            className="ml-3 font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
            style={{ width: '85px', marginRight: '-60px', height: '85px', zIndex: 2 }}
          >
            <img
              src={logoExcelRound}
              alt="icon"
              style={{ width: '65px', height: '65px' }}
              onClick={() => downloadTemplate('practices')} // Ajusta el tamaño según sea necesario
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-800 hover:shadow-lg hover:scale-105"
            onClick={() => downloadTemplate('practices')}
            style={{ paddingLeft: '45px' }} // Ajusta el espacio a la izquierda para acomodar la imagen
          >
            Descarregar Plantilla Pràctiques
          </label> */}
      </div>
      )}
      {user.role_id === 1 && (
        <div className="flex items-center justify-center col-span-3 py-5">
          <label className="ml-4 font-bold rounded-lg text-white bg-indigo-800 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105">
            Importar Alumnes
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              className="hidden"
            />
          </label>
          <label className="ml-4 font-bold rounded-lg text-white bg-indigo-800 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105">
            Importar Pràctiques
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUploadPracts}
              className="hidden"
            />
          </label>
        </div>
      )}
      {pageOptions.length > 1 && (
        <div
          className={`flex items-center justify-center py-5 ${
            user.role_id === 2 ? 'col-span-4' : 'col-span-3'
          }`}
        >
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white bg-indigo-800 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>
          <span>
            Pàgina <strong>{(pageIndex + 1).toLocaleString()}</strong> de{' '}
            <strong>{pageOptions.length.toLocaleString()} {' ('}
              {datos.length.toLocaleString()} registres{')'}
            </strong>
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white bg-indigo-800 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>
      )}
      <div
        className={`flex items-center py-5 ${
          user.role_id === 2 ? 'justify-end col-span-4' : 'justify-center col-span-3'
        }`}
      >
        {pageOptions.length > 1 && (
          <select
            className="ml-4 font-bold rounded-lg text-white bg-indigo-800 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[25, 75, 150, 250, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} registres
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
}

StudentsTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array,
  onSuccess: PropTypes.func.isRequired, // Ensure this is here
  onSuccessPracts: PropTypes.func.isRequired, // Ensure this is here

};
