// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import SinglesTable from 'components/ui/Table/SinglesTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function SinglesCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])

  // Columnas:
  const columns = [
    {
      Header: 'DNI',
      accessor: 'Singles_DNI',
      Filter: ColumnFilter,
      Type: 'DNI',
      Key: 'DNI'
    },
    {
      Header: 'Nº Cliente',
      accessor: 'Singles_IdInterno',
      Filter: ColumnFilter,
      Type: 'Idinterno',
      Key: 'Idinterno'
    },
    {
      Header: 'Nom',
      accessor: 'Singles_Name',
      Filter: ColumnFilter,
      Type: 'Name',
      Key: 'Name'
    },
    {
      Header: 'Cognoms',
      accessor: 'Singles_Surnames',
      Filter: ColumnFilter,
      Type: 'Surnames',
      Key: 'Surnames'
    },
    {
      Header: 'Correu',
      accessor: 'Singles_Email',
      Filter: ColumnFilter,
      Type: 'Email',
      Key: 'Email'
    }
  ]

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Singles_Id: item.Id,
          Singles_Name: item.Name,
          Singles_Surnames: item.Surnames,
          Singles_DNI: item.DNI,
          Singles_IdInterno: item.Idinterno,
          Singles_Email: item.Email
        })
      })
      setTableData(itemsData)
    }
  }, [items])

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="overflow-hidden">
          {getTableData.length > 0 && (
            <SinglesTable
              data={getTableData}
              columns={columns}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

SinglesCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
