import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import useFetch from 'hooks/useFetch';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import StudentsTable from 'components/ui/Table/StudentsTable';
import ColumnFilter from 'components/ui/Table/ColumnFilter';
import CheckCell from './CheckCell';
import StudentsTableBar from 'components/ui/Table/StudentsTableBar';
import  DateRangeColumnFilter  from 'components/ui/Table/DateRangeColumnFilter';

export default function StudentsCompGrid({ handleClick }) {
  const { user } = useContext(UserContext);
  const [getApiCall, setApiCall] = useFetch();
  const [getTableData, setTableData] = useState([]);
  const dateRangeStartRef = useRef(null);
  const dateRangeEndRef = useRef(null);

  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd-MM-yyyy') : '';
  };

  const columnsRole1 = [
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI',
      accessor: 'DNI',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni'
    },
    {
      Header: 'Cognoms, Nom',
      // accessor: (row) => `${row.COGNOM} ${row.NOM}`,
      accessor: (row) => `${row.NOM}`,
      // accessor: 'COGNOMS_NOM',
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom'
    },
    {
      Header: 'Num SS',
      accessor: 'NUM_SS',
      Filter: ColumnFilter,
      Type: 'num_ss',
      Key: 'num_ss'
    },
    {
      Header: 'Data Naixement',
      accessor: (row) => formatDate(row.DATA_NAIXAMENT),
      Filter: ColumnFilter,
      Type: 'data_naixement',
      Key: 'data_naixement'
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: DateRangeColumnFilter,
      // filter: 'includes',
      filter: 'dateBetween',
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: DateRangeColumnFilter,
      // filter: 'includes',
      filter: 'dateBetween',
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
  ];

  const columnsRole2 = [
    {
      Header: 'Codi Centre',
      accessor: 'CODI_CENTRE',
      Filter: ColumnFilter,
      Type: 'codi_centre',
      Key: 'codi_centre'
    },
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI/NIE/PASSAPORT',
      accessor: 'DNI_NIE_PASSAPORT',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni',
      width: 180,
    },
    {
      Header: 'Cognoms, Nom',
      accessor: 'COGNOMS_NOM',
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom',
      width: 500,
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: DateRangeColumnFilter,
      // filter: 'includes',
      // Filter: (props) => <DateRangeColumnFilter {...props} ref={dateRangeStartRef} />,
      filter: 'dateBetween',
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Inici',
      accessor: 'CHECK_TASCA_INICI',
      id: 'checks_center_inici',
      Filter: ColumnFilter,
      Type: 'check_tasca_inici',
      Key: 'check_tasca_inici',
      Cell: CheckCell
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: DateRangeColumnFilter,
      // filter: 'includes',
      filter: 'dateBetween',
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
    {
      Header: 'Final',
      accessor: 'CHECK_TASCA_FINAL',
      id: 'checks_center_final',
      Filter: ColumnFilter,
      Type: 'check_tasca_final',
      Key: 'check_tasca_final',
      Cell: CheckCell
    }
  ];

   const fetchStudents = useCallback(() => {
    let apiCall;
    if (user.role_id === 1) {
      apiCall = {
        url: `students/bytchr/${user.id}`,
        method: 'GET',
        messageKo: 'Error al carregar els alumnes del professor'
      };
    } else if (user.role_id === 2) {
      apiCall = {
        url: 'students',
        method: 'GET',
        messageKo: 'Error al carregar els alumnes'
      };
    }

    setApiCall(apiCall);
  }, [user, setApiCall]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
  if (getApiCall.data) {
    // console.log('Datos recibidos de la API:', getApiCall.data); // Depuración

    // Transformar los datos según el rol del usuario
    const transformedData = getApiCall.data.map((item) => ({
      CODI_BECARI: item.CODI_BECARI, // Común para ambos roles
      DNI: user.role_id === 1 ? item.DNI_TEACHER : item.DNI_ADMIN,
      COGNOMS_NOM: user.role_id === 1 ? item.COGNOMS_NOM_TEACHER : item.COGNOMS_NOM_ADMIN,
      NUM_SS: user.role_id === 1 ? item.NUM_SS_TEACHER : item.NUM_SS_ADMIN,
      DATA_NAIXAMENT: item.DATA_NAIXAMENT, // Común para ambos roles
      DATA_INICI: user.role_id === 1 ? item.DATA_INICI_TEACHER : item.DATA_INICI_ADMIN,
      DATA_FINALITZACIO: user.role_id === 1 ? item.DATA_FINALITZACIO_TEACHER : item.DATA_FINALITZACIO_ADMIN,
      CHECK_TASCA_INICI: user.role_id === 1 ? item.CHECK_TASCA_INICI_TEACHER : item.CHECK_TASCA_INICI_ADMIN,
      CHECK_TASCA_FINAL: user.role_id === 1 ? item.CHECK_TASCA_FINAL_TEACHER : item.CHECK_TASCA_FINAL_ADMIN,
      CODI_CENTRE: item.CODI_CENTRE, // Solo usado en Role 2 (según columnsRole2)
      DNI_NIE_PASSAPORT: item.DNI_NIE_PASSAPORT, // Solo usado en Role 2
    }));

    setTableData(transformedData);
  }
}, [getApiCall.data, user.role_id]);


  useEffect(() => {
    if (getApiCall.data) {
      
      setTableData(getApiCall.data);
    }
  }, [getApiCall.data]);

  const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;
  // const columns = columnsRole2;
  

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      <StudentsTable
        data={getTableData}
        columns={columns}
        handleClick={handleClick}
        refreshData={fetchStudents} // Pass refreshData function to StudentsTable
      />
    </div>
  );
}

StudentsCompGrid.propTypes = {
  handleClick: PropTypes.func,
};
// import PropTypes from 'prop-types';
// import { useEffect, useState } from 'react';
// import StudentsTable from 'components/ui/Table/StudentsTable';
// import ColumnFilter from 'components/ui/Table/ColumnFilter';
// import DateRangeColumnFilter from 'components/ui/Table/DateRangeColumnFilter';
// import CheckCell from './CheckCell';

// export default function StudentsCompGrid({ items, handleClick, role }) {
//   const [getTableData, setTableData] = useState([]);
//   console.log("gettabldataaaaa", getTableData)

//   const columns = [
//     {
//       Header: 'Codi Becari',
//       accessor: role === 1 ? 'CODI_BECARI_TEACHER' : 'CODI_BECARI_ADMIN',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari',
//     },
//     {
//       Header: 'DNI',
//       accessor: role === 1 ? 'DNI_TEACHER' : 'DNI_ADMIN',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni',
//     },
//     {
//       Header: 'Cognoms, Nom',
//       accessor: role === 1 ? 'COGNOMS_NOM_TEACHER' : 'COGNOMS_NOM_ADMIN',
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom',
//     },
//     {
//       Header: 'Num SS',
//       accessor: role === 1 ? 'NUM_SS_TEACHER' : 'NUM_SS_ADMIN',
//       Filter: ColumnFilter,
//       Type: 'num_ss',
//       Key: 'num_ss',
//     },
//     {
//       Header: 'Data Inici',
//       accessor: role === 1 ? 'DATA_INICI_TEACHER' : 'DATA_INICI_ADMIN',
//       Filter: DateRangeColumnFilter,
//       Type: 'data_inici',
//       Key: 'data_inici',
//     },
//     {
//       Header: 'Inici',
//       accessor: role === 1 ? 'CHECK_TASCA_INICI_TEACHER' : 'CHECK_TASCA_INICI_ADMIN',
//       id: 'checks_center_inici',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_inici',
//       Key: 'check_tasca_inici',
//       Cell: CheckCell,
//     },
//     {
//       Header: 'Data Finalització',
//       accessor: role === 1 ? 'DATA_FINALITZACIO_TEACHER' : 'DATA_FINALITZACIO_ADMIN',
//       Filter: DateRangeColumnFilter,
//       Type: 'data_finalitzacio',
//       Key: 'data_finalitzacio',
//     },
//     {
//       Header: 'Final',
//       accessor: role === 1 ? 'CHECK_TASCA_FINAL_TEACHER' : 'CHECK_TASCA_FINAL_ADMIN',
//       id: 'checks_center_final',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_final',
//       Key: 'check_tasca_final',
//       Cell: CheckCell,
//     },
//   ];

//   useEffect(() => {
//     if (items) {
//       const itemsData = items.map((item) => ({
//         CODI_BECARI_TEACHER: item.CODI_BECARI,
//         DNI_TEACHER: item.DNI,
//         COGNOMS_NOM_TEACHER: item.NOM,
//         NUM_SS_TEACHER: item.NUM_SS,
//         DATA_INICI_TEACHER: item.DATA_INICI,
//         CHECK_TASCA_INICI_TEACHER: item.CHECK_TASCA_INICI,
//         DATA_FINALITZACIO_TEACHER: item.DATA_FINALITZACIO,
//         CHECK_TASCA_FINAL_TEACHER: item.CHECK_TASCA_FINAL,
//         CODI_BECARI_ADMIN: item.CODI_BECARI,
//         DNI_ADMIN: item.DNI,
//         COGNOMS_NOM_ADMIN: item.NOM,
//         NUM_SS_ADMIN: item.NUM_SS,
//         DATA_INICI_ADMIN: item.DATA_INICI,
//         CHECK_TASCA_INICI_ADMIN: item.CHECK_TASCA_INICI,
//         DATA_FINALITZACIO_ADMIN: item.DATA_FINALITZACIO,
//         CHECK_TASCA_FINAL_ADMIN: item.CHECK_TASCA_FINAL,
//       }));
//       setTableData(itemsData);
//     }
//   }, [items]);
//   console.log(items);
//     console.log("gettabldataaaaa", getTableData)


//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       {getTableData.length > 0 && (
//         <StudentsTable
//           data={getTableData}
//           columns={columns}
//           handleClick={handleClick}
//         />
//       )}
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   items: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
//   role: PropTypes.number.isRequired,
// };








// import PropTypes from 'prop-types';
// import { useEffect, useState } from 'react';
// import StudentsTable from 'components/ui/Table/StudentsTable';
// import ColumnFilter from 'components/ui/Table/ColumnFilter';
// import DateRangeColumnFilter from 'components/ui/Table/DateRangeColumnFilter';
// import CheckCell from './CheckCell';

// export default function StudentsCompGrid({ items, handleClick }) {
//   const [getTableData, setTableData] = useState([]);

//   const columns = [
//     {
//       Header: 'Codi Becari',
//       accessor: 'CODI_BECARI',
//       Filter: ColumnFilter,
//       Type: 'codi_becari',
//       Key: 'codi_becari',
//     },
//     {
//       Header: 'DNI',
//       accessor: 'DNI',
//       Filter: ColumnFilter,
//       Type: 'dni',
//       Key: 'dni',
//     },
//     {
//       Header: 'Cognoms, Nom',
//       accessor: 'COGNOMS_NOM',
//       Filter: ColumnFilter,
//       Type: 'cognom_nom',
//       Key: 'cognom_nom',
//     },
//     {
//       Header: 'Num SS',
//       accessor: 'NUM_SS',
//       Filter: ColumnFilter,
//       Type: 'num_ss',
//       Key: 'num_ss',
//     },
//     {
//       Header: 'Data Inici',
//       accessor: 'DATA_INICI',
//       Filter: DateRangeColumnFilter,
//       Type: 'data_inici',
//       Key: 'data_inici',
//     },
//     {
//       Header: 'Inici',
//       accessor: 'CHECK_TASCA_INICI',
//       id: 'checks_center_inici',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_inici',
//       Key: 'check_tasca_inici',
//       Cell: CheckCell,
//     },
//     {
//       Header: 'Data Finalització',
//       accessor: 'DATA_FINALITZACIO',
//       Filter: DateRangeColumnFilter,
//       Type: 'data_finalitzacio',
//       Key: 'data_finalitzacio',
//     },
//     {
//       Header: 'Final',
//       accessor: 'CHECK_TASCA_FINAL',
//       id: 'checks_center_final',
//       Filter: ColumnFilter,
//       Type: 'check_tasca_final',
//       Key: 'check_tasca_final',
//       Cell: CheckCell,
//     },
//   ];

//   useEffect(() => {
//     if (items) {
//       const itemsData = items.map((item) => ({
//         CODI_BECARI: item.CODI_BECARI,
//         DNI: item.DNI,
//         COGNOMS_NOM: `${item.COGNOM} ${item.NOM}`,
//         NUM_SS: item.NUM_SS,
//         DATA_INICI: item.DATA_INICI,
//         CHECK_TASCA_INICI: item.CHECK_TASCA_INICI,
//         DATA_FINALITZACIO: item.DATA_FINALITZACIO,
//         CHECK_TASCA_FINAL: item.CHECK_TASCA_FINAL,
//       }));
//       setTableData(itemsData);
//     }
//   }, [items]);

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       {getTableData.length > 0 && (
//         <StudentsTable
//           data={getTableData}
//           columns={columns}
//           handleClick={handleClick}
//         />
//       )}
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   items: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };