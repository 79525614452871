import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from 'contexts/UserContext';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { ModalContext } from 'contexts/ModalContext';
import axios from 'axios';
import logoExcelRound from 'assets/img/Excel-2-icon.png';
import logoBorrarRound from 'assets/img/borrarblanc.png';
import logoCheckRound from 'assets/img/checkblanc.png';
import logoTxt from 'assets/img/txtroundicon.png';

// COMPONENTS
import FilterUp from 'components/icons/FilterUp';
import FilterDown from 'components/icons/FilterDown';
import SchoolsTableBar from './SchoolsTableBar';
import StudentsTableBar from './StudentsTableBar';
import { FaCalendarAlt } from 'react-icons/fa';
// import { DateRangeColumnFilter } from 'components/ui/Table/DateRangeColumnFilter';

export default function StudentsTable({ data, columns, handleClick, refreshData }) {
  if (!data) return null; // Asegúrate de que 'data' esté definido
  const { openModal } = useContext(ModalContext);
  const startDateRef = useRef(null); // Referencia para el filtro de fecha de inicio
  const endDateRef = useRef(null); 
  const formRef = useRef(null);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState({});  // Almacena los seleccionados
  const [iniciCheck, setIniciCheck] = useState('');  // Almacena el nuevo estado de INICI_CHECK
  const [fiCheck, setFiCheck] = useState('');  // Almacena el nuevo estado de FI_CHECK
  const [showForm, setShowForm] = useState(false);  // Controla la visibilidad del formulario
  const [filterStatus, setFilterStatus] = useState(''); // Almacena el estado del filtro (habilitados/inhabilitados)
  const [selectAll, setSelectAll] = useState(false); // Estado para controlar selección de todos los checkbox
  


    // Estado para filtros iniciales
  const [initialFilters, setInitialFilters] = useState(() => {
    const storedFilters = localStorage.getItem('studentsTableFilters');
    return storedFilters ? JSON.parse(storedFilters) : [];
  });

  // Función para manejar filtros persistentes
  const handleFiltersChange = (filters) => {
    localStorage.setItem('studentsTableFilters', JSON.stringify(filters));
  };


  const handleCalendarClick = (student) => {
    navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
  };

  const handleSelectAll = () => {
  const newSelectedRows = {};

  if (!selectAll) {
    // Filtrar `filteredRows` según el estado de `filterStatus`
    filteredRows
      .filter(row => {
        const isLocked = lockedStudentIds.includes(row.original.ID_ALUMNES);
        if (filterStatus === 'habilitados') return !isLocked;
        if (filterStatus === 'inhabilitados') return isLocked;
        return true; // Seleccionar todos si `filterStatus` es "" (todos)
      })
      .forEach((row) => {
        newSelectedRows[row.original.ID_ALUMNES] = true;
      });
  }

  setSelectedRows(newSelectedRows);
  setSelectAll(!selectAll); // Cambiar el estado de selectAll
};

  // Maneja el clic del checkbox para seleccionar usuarios
  const handleCheckboxClick = (row) => {
    const studentId = row.original.ID_ALUMNES;

    setSelectedRows((prev) => {
      const newState = {
        ...prev,
        [studentId]: !prev[studentId], // Alterna el estado seleccionado de la fila
      };

      return newState;
    });
  };

  // Función para manejar los cambios en INICI_CHECK y FI_CHECK
  const handleIniciCheckChange = (e) => {
    setIniciCheck(e.target.value);  // Actualiza el estado de INICI_CHECK
  };

  const handleFiCheckChange = (e) => {
    setFiCheck(e.target.value);  // Actualiza el estado de FI_CHECK
  };

  useEffect(() => {
    if (showForm && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showForm]);

   useEffect(() => {
    const hasSelectedRows = Object.keys(selectedRows).some((key) => selectedRows[key]);
    if (!hasSelectedRows) {
      setShowForm(false);
    }
  }, [selectedRows]);


  const handleApplyChanges = async () => {
    const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

    if (selectedUsers.length === 0) {
      console.log('No hay usuarios seleccionados');
      return;
    }

      // Guardar filtros actuales antes de refreshData
  const currentFilters = JSON.stringify(filters);
  localStorage.setItem('studentsTableFilters', currentFilters);
    // console.log('Usuarios seleccionados para aplicar cambios:', selectedUsers);

    for (const userId of selectedUsers) {
      try {
        // Comprobar si el usuario está deshabilitado (es decir, en la lista de usuarios bloqueados)
        const isLocked = lockedStudentIds.includes(userId);

        // Realizar el PATCH solo para prácticas (INICI_CHECK y FI_CHECK) independientemente de `isLocked`
        const response = await fetch(process.env.REACT_APP_API_URL + `/students/${userId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            INICI_CHECK: iniciCheck,
            FI_CHECK: fiCheck,
          }),
        });

        if (response.ok) {
          // console.log(`Usuario ${userId} actualizado con INICI_CHECK: ${iniciCheck}, FI_CHECK: ${fiCheck}`);
        } else {
          // console.error(`Error al actualizar el usuario ${userId}`);
        }
      } catch (error) {
        // console.error('Error en la solicitud PATCH:', error);
      }
    }

    await refreshData();

    const storedFilters = localStorage.getItem('studentsTableFilters');
  if (storedFilters) {
    const parsedFilters = JSON.parse(storedFilters);
    setAllFilters(parsedFilters); // Aplica los filtros
  }
    setSelectedRows({});
    setShowForm(false);
  };


  const fetchLockedStudents = async () => {
    try {
      const response = await fetch( process.env.REACT_APP_API_URL + '/students');
      const students = await response.json();

      // Filtrar los alumnos que tienen ambos CHECK_TASCA_INICI y CHECK_TASCA_FINAL en 1
      const lockedStudents = students.filter(
        (student) => student.CHECK_TASCA_INICI === 1 && student.CHECK_TASCA_FINAL === 1
      );

      return lockedStudents.map(student => student.ID_ALUMNES);  // Devuelve solo los ID_ALUMNES de los bloqueados
    } catch (error) {
      console.error('Error fetching students:', error);
      return [];
    }
  };

    const [lockedStudentIds, setLockedStudentIds] = useState([]);

    useEffect(() => {
      // Obtener los alumnos bloqueados al cargar el componente
      const getLockedStudents = async () => {
        const lockedIds = await fetchLockedStudents();
        setLockedStudentIds(lockedIds);  // Guardar los IDs de los alumnos bloqueados en el estado
      };

      getLockedStudents();
    }, []);

// DESCARGAR EXCEL ALUMNOS SELECCIONADOS PARA LAS PRACTICAS!
const downloadSelectedPractices = async () => {
  const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

  if (selectedUsers.length === 0) {
    console.log('No hay usuarios seleccionados');
    return;
  }

  try {
    const formattedData = [];

    // Obtener los datos para cada usuario seleccionado
    for (const userId of selectedUsers) {
      const response = await fetch(process.env.REACT_APP_API_URL + `/studentsTeacher/${userId}`);
      const userData = await response.json();

      // Formatear las fechas de inicio y fin de prácticas
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van del 0 al 11
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      formattedData.push({
        "CODI BECARI": userData.CODI_BECARI,
        "DNI NIE PASS": userData.DNI,
        // "COGNOMS": userData.COGNOM,
        "NOM": userData.NOM,
        "INICI PRACTIQUES": formatDate(userData.INICI_PRACTIQUES),
        "FI PRACTIQUES": formatDate(userData.FI_PRACTIQUES),
        "GENER": userData.GENER,
        "FEBRER": userData.FEBRER,
        "MARÇ": userData.MARÇ,
        "ABRIL": userData.ABRIL,
        "MAIG": userData.MAIG,
        "JUNY": userData.JUNY,
        "JULIOL": userData.JULIOL,
        "AGOST": userData.AGOST,
        "SETEMBRE": userData.SETEMBRE,
        "OCTUBRE": userData.OCTUBRE,
        "NOVEMBRE": userData.NOVEMBRE,
        "DESEMBRE": userData.DESEMBRE
      });
    }

    if (formattedData.length === 0) {
      console.log('No se encontraron datos para los usuarios seleccionados');
      return;
    }

    // Crear el archivo Excel con exceljs
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Pràctiques');

    // Definir las columnas
    worksheet.columns = [
      { header: 'CODI BECARI', key: 'CODI BECARI', width: 20 },
      { header: 'DNI NIE PASS', key: 'DNI NIE PASS', width: 20 },
      // { header: 'COGNOMS', key: 'COGNOMS', width: 30 },
      { header: 'NOM', key: 'NOM', width: 30 },
      { header: 'INICI PRACTIQUES', key: 'INICI PRACTIQUES', width: 20 },
      { header: 'FI PRACTIQUES', key: 'FI PRACTIQUES', width: 20 },
      { header: 'GENER', key: 'GENER', width: 10 },
      { header: 'FEBRER', key: 'FEBRER', width: 10 },
      { header: 'MARÇ', key: 'MARÇ', width: 10 },
      { header: 'ABRIL', key: 'ABRIL', width: 10 },
      { header: 'MAIG', key: 'MAIG', width: 10 },
      { header: 'JUNY', key: 'JUNY', width: 10 },
      { header: 'JULIOL', key: 'JULIOL', width: 10 },
      { header: 'AGOST', key: 'AGOST', width: 10 },
      { header: 'SETEMBRE', key: 'SETEMBRE', width: 10 },
      { header: 'OCTUBRE', key: 'OCTUBRE', width: 10 },
      { header: 'NOVEMBRE', key: 'NOVEMBRE', width: 10 },
      { header: 'DESEMBRE', key: 'DESEMBRE', width: 10 }
    ];

    // Rellenar los datos
    formattedData.forEach((data) => {
      worksheet.addRow(data);
    });

    worksheet.eachRow((row) => {
      row.height = 25; // Altura específica para todas las filas
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC00' }  // Color amarillo
      };
      cell.font = { bold: true, color: { argb: 'FF0000FF' } };  // Texto en negrita y azul
      cell.alignment = { vertical: 'middle', horizontal: 'center' };  // Alinear en el centro
    });

    // Aplicar validación de datos a las columnas de meses
    const monthColumns = ['GENER', 'FEBRER', 'MARÇ', 'ABRIL', 'MAIG', 'JUNY', 'JULIOL', 'AGOST', 'SETEMBRE', 'OCTUBRE', 'NOVEMBRE', 'DESEMBRE'];

    monthColumns.forEach((col) => {
      worksheet.getColumn(col).eachCell((cell, rowNumber) => {
        if (rowNumber !== 1) {  // Excluir la cabecera
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCE5FF' }  // Color azul claro
          };
        }

        cell.protection = { locked: false };  // Hacer la columna editable

        // Agregar validación de datos para que solo acepte valores entre 0 y 30, sin decimales
        cell.dataValidation = {
          type: 'whole',
          operator: 'between',
          formulae : [ 0 ,  30 ], 
          showErrorMessage: true,
          errorTitle: 'Valor inválido',
          error: 'Por favor, ingresa un número entero entre 0 y 30.',
        };
      });
    });

    // Aplicar color amarillo pastel a "CODI BECARI", "DNI", "NOM" y "COGNOMS"
    // ['CODI BECARI', 'DNI NIE PASS', 'NOM', 'COGNOMS', 'INICI PRACTIQUES', 'FI PRACTIQUES'].forEach((col) => {
    ['CODI BECARI', 'DNI NIE PASS', 'NOM', 'INICI PRACTIQUES', 'FI PRACTIQUES'].forEach((col) => {
      worksheet.getColumn(col).eachCell((cell, rowNumber) => {
        if (rowNumber !== 1) {  // Excluir la cabecera
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFF2CC' }  // Color amarillo pastel
          };
        }
      });
    });

    // Bloquear la hoja para proteger las celdas no editables
    worksheet.protect('Gest1net+Cod1na', {
      selectLockedCells: true,
      selectUnlockedCells: true,
    });

    // Generar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blobData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blobData, 'Practiques_Seleccionades.xlsx');

  } catch (error) {
    console.error('Error al descargar las prácticas:', error);
  }
};

function dateBetweenFilterFn(rows, id, filterValue) {
  if (!Array.isArray(rows)) {
    console.error("rows no es un array:", rows);
    return [];
  }

  if (!Array.isArray(filterValue)) {
      // console.error("filterValue no es un array válido:", filterValue);
      return rows;
  }

  let [min, max] = filterValue;

  // Convertimos min y max a formato Date de JavaScript (YYYY-MM-DD)
  min = min ? new Date(min) : undefined;
  max = max ? new Date(max) : undefined;

  return rows.filter(row => {
    const rowDate = row.values[id];
    if (!rowDate || typeof rowDate !== 'string') {
      // console.warn(`Fecha inválida en row.values[${id}]:`, rowDate);
      return false;
    }

    // Convertimos la fecha del row de DD-MM-YYYY a YYYY-MM-DD
    const [day, month, year] = rowDate.split('-');
    if (!day || !month || !year) {
      console.warn(`Formato de fecha incorrecto: ${rowDate}`);
      return false;
    }

    const parsedRowDate = new Date(`${year}-${month}-${day}`);

    return (
      (min ? parsedRowDate >= min : true) &&
      (max ? parsedRowDate <= max : true)
    );
  });
}


  // function dateBetweenFilterFn(rows, id, filterValue) {
  //   let [min, max] = filterValue;

  //   // Convertimos min y max a formato Date de JavaScript (YYYY-MM-DD)
  //   min = min ? new Date(min) : undefined;
  //   max = max ? new Date(max) : undefined;

  //   return rows.filter(row => {
  //     const rowDate = row.values[id];
  //     // Aquí convertimos la fecha del row (que está en formato DD-MM-YYYY) a YYYY-MM-DD para la comparación
  //     const [day, month, year] = rowDate.split('-');
  //     const parsedRowDate = new Date(`${year}-${month}-${day}`);

  //     return (
  //       (min ? parsedRowDate >= min : true) &&
  //       (max ? parsedRowDate <= max : true)
  //     );
  //   });
  // }

  // dateBetweenFilterFn.autoRemove = val => !val; // Remueve el filtro si no hay valores


const handleDeleteModalForSelected = () => {
  const selectedIds = Object.keys(selectedRows)
    .filter((key) => selectedRows[key]) // Filtrar IDs seleccionados
    .map((key) => parseInt(key, 10)); // Convertir a números

  if (selectedIds.length === 0) {
    console.log('No hay alumnos seleccionados para eliminar');
    return;
  }

  // Datos para el modal de confirmación
  const modalData = {
    ids: selectedIds,
    handleDelete: () => handleDeleteSelected(selectedIds), // Pasar los IDs seleccionados a la función de eliminación
    type: 'DELETE',
  };

  openModal(modalData); // Abre el modal con los datos
};

// funcion eliminar alumnos seleccionados
const handleDeleteSelected = async (selectedIds) => {
  try {
    for (const id of selectedIds) {
      const url = `${process.env.REACT_APP_API_URL}/students/${id}`;
      await axios.delete(url); // Eliminar cada ID seleccionado
    }

    console.log(`Alumnos eliminados: ${selectedIds.join(', ')}`);
    await refreshData(); // Refrescar los datos después de eliminar
    setSelectedRows({}); // Limpiar las selecciones
  } catch (error) {
    console.error('Error al eliminar alumnos:', error);
  }
};

// funcion de descargar alumnos seleccionados
const downloadSelectedStudents = async () => {
  const selectedIds = Object.keys(selectedRows).filter((key) => selectedRows[key]);

  if (selectedIds.length === 0) {
    console.log('No hay alumnos seleccionados para descargar');
    return;
  }

  try {
    // Obtener datos de todos los alumnos
    const response = await fetch(process.env.REACT_APP_API_URL + '/students/all');
    const allStudents = await response.json();

    // Filtrar los datos de los alumnos seleccionados
    const formattedData = selectedIds.map((id) => {
      const student = allStudents.find((student) => student.ID_ALUMNES === parseInt(id));
      if (!student) return null;

      // Formatear las fechas
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
      };

      return {
        CodiBecari: student.CODI_BECARI,
        CodiCentre: student.CODI_CENTRE,
        CognomsNom: student.COGNOMS_NOM,
        DNI: student.DNI_NIE_PASSAPORT,
        NumSS: student.NUM_SS,
        DataNaixament: formatDate(student.DATA_NAIXAMENT),
        DataInici: formatDate(student.DATA_INICI),
        DataFinalitzacio: formatDate(student.DATA_FINALITZACIO),
        CheckTascaInici: student.CHECK_TASCA_INICI,
        CheckTascaFinal: student.CHECK_TASCA_FINAL,
        Observacions: student.OBSERVACIONS_ALUMNE || '',
        Gener: student.January || 0,
        Febrer: student.February || 0,
        Març: student.March || 0,
        Abril: student.April || 0,
        Maig: student.May || 0,
        Juny: student.June || 0,
        Juliol: student.July || 0,
        Agost: student.August || 0,
        Setembre: student.September || 0,
        Octubre: student.October || 0,
        Novembre: student.November || 0,
        Desembre: student.December || 0,
      };
    }).filter(Boolean);

    // Crear el archivo Excel con ExcelJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Alumnes Seleccionats');

    // Definir columnas y estilos
    worksheet.columns = [
      { header: 'Codi Becari', key: 'CodiBecari', width: 15 },
      { header: 'Codi Centre', key: 'CodiCentre', width: 15 },
      { header: 'Cognoms Nom', key: 'CognomsNom', width: 50 },
      { header: 'DNI', key: 'DNI', width: 15 },
      { header: 'Numero Seguretat Social', key: 'NumSS', width: 25 },
      { header: 'Data Naixement', key: 'DataNaixament', width: 15 },
      { header: 'Inici Pràctiques', key: 'DataInici', width: 15 },
      { header: 'Fi Pràctiques', key: 'DataFinalitzacio', width: 15 },
      { header: 'Check Inici', key: 'CheckTascaInici', width: 15 },
      { header: 'Check Final', key: 'CheckTascaFinal', width: 15 },
      { header: 'Observacions', key: 'Observacions', width: 30 },
      { header: 'Gener', key: 'Gener', width: 10 },
      { header: 'Febrer', key: 'Febrer', width: 10 },
      { header: 'Març', key: 'Març', width: 10 },
      { header: 'Abril', key: 'Abril', width: 10 },
      { header: 'Maig', key: 'Maig', width: 10 },
      { header: 'Juny', key: 'Juny', width: 10 },
      { header: 'Juliol', key: 'Juliol', width: 10 },
      { header: 'Agost', key: 'Agost', width: 10 },
      { header: 'Setembre', key: 'Setembre', width: 10 },
      { header: 'Octubre', key: 'Octubre', width: 10 },
      { header: 'Novembre', key: 'Novembre', width: 10 },
      { header: 'Desembre', key: 'Desembre', width: 10 },
    ];

    // Estilo de cabecera
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC00' },
      };
      cell.font = { bold: true, color: { argb: 'FF0000FF' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    worksheet.getRow(1).height = 25;

    // Rellenar los datos y estilos de las filas
    formattedData.forEach((data, index) => {
      const row = worksheet.addRow(data);
      row.height = 25;
      if (index % 2 === 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF3F3F3' }, // Gris claro para filas alternas
          };
        });
      }
    });

    // Descargar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Alumnes_Seleccionats.xlsx');
  } catch (error) {
    console.error('Error al descargar alumnos seleccionados:', error);
  }
};

// const downloadSelectedStudentsAsText = () => {
//   const selectedIds = Object.keys(selectedRows).filter((key) => selectedRows[key]);

//   if (selectedIds.length === 0) {
//     console.log('No hay alumnos seleccionados para descargar');
//     return;
//   }

//   let textData = '';

//   selectedIds.forEach((id) => {
//     const student = data.find((student) => student.ID_ALUMNES === parseInt(id));
//     if (!student) return;

//     console.log('Datos del alumno seleccionado:', student);

//     // Formatear datos necesarios
//     const formatDate = (dateString) => {
//       if (!dateString) return '';
//       const date = new Date(dateString);
//       return format(date, 'yyyyMMdd'); // Formato AAAAMMDD
//     };

//     const tipoRegistro = '01';
//     const codigoEmpresa = (student.CODI_CENTRE || '').padStart(5, '0');
//     const codigoTrabajo = (student.CODI_CENTRE || '').padStart(5, '0'); // Reutiliza el código del centro
//     const codigoTrabajador = (student.CODI_BECARI || '').padStart(6, '0');
//     const dni = (student.DNI_NIE_PASSAPORT || '').padEnd(9, ' ');
//     const apellidosNombre = (student.COGNOMS_NOM || '')
//       .replace(',', '')
//       .split(' ')
//       .filter((word) => word)
//       .join(' ')
//       .toUpperCase()
//       .padEnd(30, ' ');
//     const numMatricula = '00000';
//     const numSS = (student.NUM_SS || '').padStart(12, '0');
//     const padre = '.'.padEnd(15, ' ');
//     const madre = '.'.padEnd(15, ' ');
//     const hscl = 'HSCL'.padEnd(33, ' ');
//     const numEscaleraPisoPuerta = '0000';
//     const municipio = 'BARCELONA'.padEnd(20, ' ');
//     const cp = '08000'.padEnd(15, '0');
//     const clavePercepcion = 'A'.padEnd(8, '0');
//     const tipoRetencion = 'A'.padEnd(21, '0');
//     const codigoSerie = ''.padEnd(16, ' ');
//     const becari = 'BECARI'.padStart(9, '0');
//     const becari0 = 'BECARI'.padStart(10, ' ');
//     const grupoTarifa = '0711301MNN'.padStart(12, ' ');
//     const fechaNacimiento = formatDate(student.DATA_NAIXAMENT);
//     const fechaInicio = formatDate(student.DATA_INICI);
//     const indicadorPluriempleado = 'N'.padEnd(31, '0');
//     const indicadorTiempoParcial = 'D000000000000000N000000000000000000000000000000000000000000000000000000000000000000'.padStart(123, ' ');
//     const horasAndTipoContrato = '000100'.padStart(36, ' ');
//     const fechaFin = formatDate(student.DATA_FINALITZACIO);
//     const nivelEstudiosTerminados = '53'.padEnd(11, ' ');
//     const numContrato = '00000000000190001000000000000019000100 0000000000000000190001001900010000000000000000';
//     const datosPagasExtras = '000000000'.padStart(14, ' ');
//     const finalDescuadrado = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'.padStart(197, ' ');
//     const finalDescuadrado1 = '00000000000000000072400000000000000000000000000000000000000000000000000000000    00   00  000000000000    0100'.padStart(410, ' ');
//     const finalPredeterminado = '00000000000000                                          0801972400000000000003724                                                                                                                                                                                                                                                                   '.padStart(453, ' ');

//     // Construir línea del archivo de texto
//     textData += `${tipoRegistro}${codigoEmpresa}${codigoTrabajo}${codigoTrabajador}${dni}${apellidosNombre}${numMatricula}${numSS}${padre}${madre}${hscl}${numEscaleraPisoPuerta}${municipio}${municipio}${cp}${clavePercepcion}${tipoRetencion}${codigoSerie}${becari}${becari0}${grupoTarifa}${fechaNacimiento}${fechaInicio}${numEscaleraPisoPuerta}${numEscaleraPisoPuerta}${fechaInicio}${fechaInicio}${indicadorPluriempleado}${indicadorTiempoParcial}${horasAndTipoContrato}${fechaInicio}${fechaFin}${nivelEstudiosTerminados}${numContrato}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${finalDescuadrado}${finalDescuadrado1}${finalPredeterminado}\n`;
//   });

//   const textBlob = new Blob([textData], { type: 'text/plain' });
//   saveAs(textBlob, 'Alumnes_Seleccionats.txt');
// };

const downloadSelectedStudentsAsText = async () => {
  const selectedIds = Object.keys(selectedRows).filter((key) => selectedRows[key]);

  if (selectedIds.length === 0) {
    console.log('No hay alumnos seleccionados para descargar');
    return;
  }

  let textData = '';

  try {
    // Solicitar datos de las escuelas
    const schoolsResponse = await fetch(process.env.REACT_APP_API_URL + '/schools/');
    const schoolsData = await schoolsResponse.json();

    // Mapear los datos de las escuelas por `CODI_CENTRE`
    const schoolDataMap = schoolsData.reduce((acc, school) => {
      acc[school.CODI] = school.CENTRE; // Ajusta esto según la estructura real de tus datos
      return acc;
    }, {});

    // Solicitar datos de los estudiantes
    const allStudentsResponse = await fetch(process.env.REACT_APP_API_URL + '/students/all');
    const allStudents = await allStudentsResponse.json();

    // Iterar sobre los estudiantes seleccionados
    for (const id of selectedIds) {
      const student = allStudents.find((s) => s.ID_ALUMNES === parseInt(id));
      if (!student) {
        console.warn(`No se encontró información para el estudiante con ID: ${id}`);
        continue;
      }

      console.log('Datos del alumno seleccionado:', student);

      // Formatear datos necesarios
      const formatDate = (dateString) => {
        if (!dateString) return ''.padStart(8, '0'); // Fecha vacía en formato esperado
        const date = new Date(dateString);
        return isNaN(date) ? ''.padStart(8, '0') : format(date, 'yyyyMMdd'); // Formato AAAAMMDD
      };

      // Campos con valores predeterminados
      const tipoRegistro = '01';
      const codigoEmpresa = (student.CODI_CENTRE || '').padStart(5, '0');
      const codigoTrabajo = (schoolDataMap[student.CODI_CENTRE] || '').padStart(5, '0'); // Usar el map de escuelas
      const codigoTrabajador = (student.CODI_BECARI || '').padStart(6, '0');
      const dni = (student.DNI_NIE_PASSAPORT || '').padEnd(9, ' ');
      const apellidosNombre = (student.COGNOMS_NOM || '')
        .replace(',', '')
        .split(' ')
        .filter((word) => word)
        .join(' ')
        .toUpperCase()
        .padEnd(30, ' ');
      const numMatricula = '00000';
      const numSS = (student.NUM_SS || '').padStart(12, '0');
      const padre = '.'.padEnd(15, ' ');
      const madre = '.'.padEnd(15, ' ');
      const hscl = 'HSCL'.padEnd(33, ' ');
      const numEscaleraPisoPuerta = '0000';
      const municipio = 'BARCELONA'.padEnd(20, ' ');
      const cp = '08000'.padEnd(15, '0');
      const clavePercepcion = 'A'.padEnd(8, '0');
      const tipoRetencion = 'A'.padEnd(21, '0');
      const codigoSerie = ''.padEnd(16, ' ');
      const becari = 'BECARI'.padStart(9, '0');
      const becari0 = 'BECARI'.padStart(10, ' ');
      const grupoTarifa = '0711301MNN'.padStart(12, ' ');
      const fechaNacimiento = formatDate(student.DATA_NAIXAMENT).padStart(9, ' ');
      const fechaInicio = formatDate(student.DATA_INICI);
      const indicadorPluriempleado = 'N'.padEnd(31, '0');
      const indicadorTiempoParcial = 'D000000000000000N000000000000000000000000000000000000000000000000000000000000000000'.padStart(123, ' ');
      const horasAndTipoContrato = '000100'.padStart(36, ' ');
      const fechaFin = formatDate(student.DATA_FINALITZACIO);
      const nivelEstudiosTerminados = '53'.padEnd(11, ' ');
      const numContrato = '00000000000190001000000000000019000100 0000000000000000190001001900010000000000000000';
      const datosPagasExtras = '000000000'.padStart(14, ' ');
      const finalDescuadrado = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'.padStart(197, ' ');
      const finalDescuadrado1 = '00000000000000000072400000000000000000000000000000000000000000000000000000000    00   00  000000000000    0100'.padStart(410, ' ');
      const finalPredeterminado = '00000000000000                                          0801972400000000000003724                                                                                                                                                                                                                                                                   '.padStart(453, ' ');

      // Construir línea del archivo de texto
      textData += `${tipoRegistro}${codigoEmpresa}${codigoTrabajo}${codigoTrabajador}${dni}${apellidosNombre}${numMatricula}${numSS}${padre}${madre}${hscl}${numEscaleraPisoPuerta}${municipio}${municipio}${cp}${clavePercepcion}${tipoRetencion}${codigoSerie}${becari}${becari0}${grupoTarifa}${fechaNacimiento}${fechaInicio}${numEscaleraPisoPuerta}${numEscaleraPisoPuerta}${fechaInicio}${fechaInicio}${indicadorPluriempleado}${indicadorTiempoParcial}${horasAndTipoContrato}${fechaInicio}${fechaFin}${nivelEstudiosTerminados}${numContrato}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${finalDescuadrado}${finalDescuadrado1}${finalPredeterminado}\n`;
    }

    // Crear y descargar el archivo TXT
    const textBlob = new Blob([textData], { type: 'text/plain' });
    saveAs(textBlob, 'Alumnes_Seleccionats.txt');
  } catch (error) {
    console.error('Error al generar el archivo TXT:', error);
  }
};




const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { filters, pageSize, pageIndex },
    filteredRows,  // Datos filtrados
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      filterTypes: { dateBetween: dateBetweenFilterFn },
      // initialState: {filters: initialFilters, pageSize: 25 },  // Tamaño de página inicial
      initialState: {
      filters: JSON.parse(localStorage.getItem('studentsTableFilters')) || [], // Usar filtros guardados
      pageSize: 25,
    },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  // Efecto para guardar los filtros cuando cambian
  useEffect(() => {
    handleFiltersChange(filters);
  }, [filters]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between w-full px-4">
        {/* Botón para seleccionar o deseleccionar todos */}
        <button
          onClick={handleSelectAll}
          className="font-bold rounded-lg text-white bg-indigo-800 mb-4 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105"
        >
          {selectAll ? 'Desseleccionar tots els alumnes' : 'Seleccionar tots els alumnes'}
        </button>

        {/* Botón para borrar */}
        <button
          type="button"
          className="font-bold rounded-lg text-white bg-indigo-800 mb-4 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105"
          onClick={() => {
            setAllFilters([]);
            columns.forEach((column) => {
              localStorage.setItem(column.accessor, undefined);
              // localStorage.removeItem(column.id);
            });
          }}
        >
          Esborrar filtres
        </button>
      </div>
      
      <div className="align-middle inline-block min-w-full">
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {(user.role_id === 1 || user.role_id === 2) && (
                  <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                    <div className="text-left text-sm ml-4 mr-4">
                      <select
                        value={filterStatus}
                        style={{ width: '100%' }}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="bg-indigo-700 text-white rounded p-1 cursor-pointer"
                      >
                        <option value="">Tots</option>
                        <option value="habilitados">Habilitats</option>
                        <option value="inhabilitados">Deshabilitats</option>
                      </select>
                    </div>
                  </th>
                )}
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  >
                    <div className="text-left text-sm ml-4 flex items-center">
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown width={10} height={10} className="inline-block ml-1 -mt-1" fill="black" />
                        ) : (
                          <FilterUp width={10} height={10} className="inline-block ml-1 -mt-1" fill="black" />
                        )
                      ) : null}
                    </div>
                    <div className="mx-4">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Calendari</div>
                </th>
              </tr>
            ))}
          </thead>

            <tbody
              style={{ borderColor: '#9CA3AF' }}
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {page
                .filter((row) => {
                  const isLocked = lockedStudentIds.includes(row.original.ID_ALUMNES);
                  if (filterStatus === 'habilitados') return !isLocked;
                  if (filterStatus === 'inhabilitados') return isLocked;
                  return true;
                })
                .map((row, index) => {
                  prepareRow(row);
                  const studentId = row.original.ID_ALUMNES;
                  const isRowLocked = lockedStudentIds.includes(studentId);
                  const disableRow = isRowLocked && user.role_id === 1; // Solo bloquea interacción para role_id === 1

                  return (
                    <tr
                      key={row.id}
                      {...row.getRowProps()}
                      onClick={(e) => !disableRow && handleClick && handleClick(e, row.original)} // Bloquea interacción según role_id
                      className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                        ${isRowLocked ? 'bg-red-300' : index % 2 === 0 ? 'bg-indigo-50' : ''}`} // Fondo rojo si está bloqueado
                    >
                      {(user.role_id === 1 || user.role_id === 2) && (
                        <td
                          className="text-sm font-medium text-center cursor-pointer"
                          style={{ width: '6.25%' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCheckboxClick(row); // Permitir selección incluso si está bloqueado
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={!!selectedRows[studentId]}
                            readOnly
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxClick(row); // Maneja la selección
                            }}
                            className="cursor-pointer form-checkbox h-5 w-5 text-indigo-800"
                          />
                        </td>
                      )}
                      {row.cells.map((cell) => (
                        <td
                          key={cell.column.id}
                          className={`text-sm font-medium py-4 ${
                            cell.column.id === 'checks_center_inici' || cell.column.id === 'checks_center_final'
                              ? ''
                              : 'pl-4'
                          }`}
                          style={{ width: cell.column.width || '12.5%' }}
                        >
                          {disableRow ? (
                            <span>{cell.render('Cell')}</span> // Mostrar valor, sin opción de editar
                          ) : (
                            cell.render('Cell') // Células editables si no está bloqueado
                          )}
                        </td>
                      ))}
                      <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCalendarClick(row.original); // Permitir acceso al calendario
                          }}
                          className="text-indigo-800 hover:text-blue-700"
                        >
                          <FaCalendarAlt size={24} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>


          <tfoot>
            <tr>
              <td colSpan={columns.length + 2} className="p-0 m-0">
                <StudentsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  // datos={data}
                  // datos={filteredRows.map(row => row.original)}
                  datos={filteredRows // Para que habilitados - deshabilitados se pasen como un filtro mas para el Excel
                    .filter((row) => {
                      const isLocked = lockedStudentIds.includes(row.original.ID_ALUMNES);

                      if (filterStatus === 'habilitados') return !isLocked; // Solo habilitados
                      if (filterStatus === 'inhabilitados') return isLocked; // Solo inhabilitados
                      return true; // Todos si no hay filtro
                    })
                    .map((row) => row.original)}
                  onSuccess={refreshData}
                  onSuccessPracts={refreshData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>


{Object.keys(selectedRows).some((key) => selectedRows[key]) && (
  <div className="mt-4 flex w-full">
    {user.role_id === 1 ? (
      // Role 1: Descargar Plantilla Prácticas y Eliminar Alumnes
      <>
        {/* Botón "Descarregar Plantilla Pràctiques" */}
        <div className="w-1/2 flex items-center mr-2">
          <button
            className="font-bold rounded-lg text-white"
            style={{
              width: '85px',
              marginRight: '-60px',
              height: '85px',
              zIndex: 2,
            }}
            onClick={downloadSelectedPractices}
          >
            <img
              src={logoExcelRound}
              alt="icon"
              style={{ width: '65px', height: '65px' }}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-[#308446] hover:shadow-lg hover:scale-10 flex-grow"
            onClick={downloadSelectedPractices}
            style={{ paddingLeft: '45px' }}
          >
            Descarregar Plantilla Pràctiques
          </label>
        </div>

        {/* Botón "Eliminar Alumnes" */}
        <div className="w-1/2 flex items-center ml-2">
          <button
            className="font-bold rounded-lg text-white"
            style={{
              width: '85px',
              marginRight: '-60px',
              height: '85px',
              zIndex: 2,
            }}
            onClick={handleDeleteModalForSelected}
          >
            <img
              src={logoBorrarRound}
              alt="delete-icon"
              style={{ width: '60px', height: '60px' }}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-red-600 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-red-800 hover:shadow-lg hover:scale-10 flex-grow"
            onClick={handleDeleteModalForSelected}
            style={{ paddingLeft: '45px' }}
          >
            Eliminar Alumnes
          </label>
        </div>
      </>
    ) : (
      // Role 2: Cambiar Check, Descargar Alumnos, Descargar TXT, Eliminar Alumnos
      <>
        {/* Botón para cambiar el "Check Tasca Inici y Final" */}
        <div className="w-1/3 flex items-center mr-2">
          <button
            className="font-bold rounded-lg text-white"
            style={{
              width: '85px',
              marginRight: '-60px',
              height: '85px',
              zIndex: 2,
            }}
            onClick={() => setShowForm(true)}
          >
            <img
              src={logoCheckRound}
              alt="check-icon"
              style={{ width: '60px', height: '60px' }}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#191970] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-[#000080] hover:shadow-lg hover:scale-10 flex-grow"
            onClick={() => setShowForm(true)}
            style={{ paddingLeft: '45px' }}
          >
            Canviar Check Inici i Final
          </label>
        </div>

        {/* Botón "Descarregar alumnes seleccionats" */}
        <div className="w-1/3 flex items-center mr-2">
          <button
            className="font-bold rounded-lg text-white"
            style={{
              width: '85px',
              marginRight: '-60px',
              height: '85px',
              zIndex: 2,
            }}
            onClick={downloadSelectedStudents}
          >
            <img
              src={logoExcelRound}
              alt="excel-icon"
              style={{ width: '65px', height: '65px' }}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-[#308446] hover:shadow-lg hover:scale-10 flex-grow"
            onClick={downloadSelectedStudents}
            style={{ paddingLeft: '45px' }}
          >
            Descarregar alumnes seleccionats
          </label>
        </div>

        {/* Botón "Descarregar TXT alumnes seleccionats" */}
        <div className="w-1/3 flex items-center mr-2">
          <button
            className="font-bold rounded-lg text-white"
            style={{
              width: '85px',
              marginRight: '-60px',
              height: '85px',
              zIndex: 2,
            }}
            onClick={downloadSelectedStudentsAsText}
          >
            <img
              src={logoTxt}
              alt="txt-icon"
              style={{ width: '75px', height: '70px' }}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#566573] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-[#2c3e50] hover:shadow-lg hover:scale-10 flex-grow"
            onClick={downloadSelectedStudentsAsText}
            style={{ paddingLeft: '55px' }}
          >
            Descarregar TXT alumnes seleccionats
          </label>
        </div>

        {/* Botón "Eliminar Alumnes" reducido */}
        <div className="w-1/6 flex items-center ml-2">
        <button
          className="font-bold rounded-lg text-white"
          style={{
            width: '85px',
            marginRight: '-60px',
            height: '85px',
            zIndex: 2,
          }}
          onClick={handleDeleteModalForSelected} // Función para eliminar handleDeleteSelectedStudents
        >
          <img
            src={logoBorrarRound} // Ruta al logo de borrar
            alt="delete-icon"
            style={{ width: '60px', height: '60px' }} // Ajusta el tamaño según sea necesario
          />
        </button>
        <label
          className="font-bold rounded-lg text-white bg-red-600 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-red-800 hover:shadow-lg hover:scale-10 flex-grow"
          onClick={handleDeleteModalForSelected} // handleDeleteSelectedStudents
          style={{ paddingLeft: '45px' }} // Ajusta el espacio a la izquierda para acomodar la imagen
        >
          Eliminar Alumnes
        </label>
        </div>
      </>
    )}
  </div>
)}







      {showForm && user.role_id === 2 && (
        <div ref={formRef} className="mt-6 bg-indigo-100 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
          <h3 className="text-indigo-900 font-bold text-lg mb-4">Actualitzar Estat</h3>
          <label htmlFor="iniciCheck" className="block text-sm font-semibold text-indigo-800 mb-2">
            Selecciona el nou estat de Check Tasca Inici:
          </label>
          <select
            id="iniciCheck"
            value={iniciCheck}
            onChange={handleIniciCheckChange}
            className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
          >
            <option value="">Selecciona una opció</option>
            <option value="0">No</option>
            <option value="1">Sí</option>
            <option value="2">Pendent</option>
          </select>

          <label htmlFor="fiCheck" className="block text-sm font-semibold text-indigo-800 mt-6 mb-2">
            Selecciona el nou estat de Check Tasca Final:
          </label>
          <select
            id="fiCheck"
            value={fiCheck}
            onChange={handleFiCheckChange}
            className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
          >
            <option value="">Selecciona una opció</option>
            <option value="0">No</option>
            <option value="1">Sí</option>
            <option value="2">Pendent</option>
          </select>

          <button onClick={handleApplyChanges} className="w-full mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 rounded-md shadow-lg transition ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Aplicar canvis
          </button>
        </div>
      )}
    </div>
  );
}

StudentsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  refreshData: PropTypes.func.isRequired,
};
