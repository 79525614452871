import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import useFetch from 'hooks/useFetch'
import { TitleContext } from 'contexts/TitleContext'
import { useLocation } from 'wouter'
import StudentsCompGrid from 'components/Students/StudentsCompGrid'
import loadingIco from 'components/ui/Logo/loading_azul.gif'
import { BsPersonAdd } from 'react-icons/bs'

export default function StudentsViewGrid({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()

  // ############################################################

  // CRUD: GET
  useEffect(() => {
    const apiCallGet = {
      url: 'students',
      method: 'GET',
      successMessage: null,
      failureMessage: 'Error de càrrega!'
    }
    setApiCallGet(apiCallGet)
  }, [setApiCallGet])

  //* Recoger datos API: GET
  useEffect(() => {
    const title = {
      name: 'Alumnes',
      buttons: [
        {
          name: (
            <span
              title="CREAR ALUMNE"
              className="w-full flex flex-row items-center"
            >
              <span className="pl-3 pr-1">
                <BsPersonAdd size={20} />
              </span>
              <span className="pl-1 pr-3">Crear Alumne</span>
            </span>
          ),
          link: `/students/new`
        }
      ],
      button: 'print',
      export: true,
      csvData:
        getApiCallGet.data !== null &&
        getApiCallGet.data !== undefined &&
        getApiCallGet.data[0] !== null
          ? getApiCallGet.data
          : null
    }
    setTitle(title)
  }, [getApiCallGet.data, setTitle])

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  const handleClick = (e, item) => {
    e.preventDefault()
    setLocation(`/students/${item.ID_ALUMNES}`)
  }

  // ############################################################

  // return getApiCallGet?.data ? (
  //   <>
  //     <div
  //       className={`w-full ${
  //         getApiCallGet.data.length > 0 ? 'block' : 'hidden'
  //       }`}
  //     >
  //       <div className="flex flex-col">
  //         <StudentsCompGrid
  //           items={getApiCallGet.data}
  //           handleClick={handleClick}
  //         />
  //       </div>
  //     </div>

  //     <div
  //       className={`w-full ${
  //         getApiCallGet.data.length > 0 ? 'hidden' : 'block'
  //       }`}
  //     >
  //       <div className="flex flex-col">
  //         <div
  //           className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
  //           role="alert"
  //         >
  //           <div className="flex">
  //             <div className="py-1">
  //               <svg
  //                 className="fill-current h-6 w-6 text-red-500 mr-4"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 viewBox="0 0 20 20"
  //               >
  //                 <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
  //               </svg>
  //             </div>
  //             <div>
  //               <p className="font-bold">Cap registre trobat.</p>
  //               <p className="text-sm">
  //                 Crea un nou alumne prement el botó{' '}
  //                 <b>
  //                   <i>Crear Alumne</i>
  //                 </b>
  //                 !
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // ) : (
  //   <div>
  //     <div className="h-full w-full flex justify-center items-center content-center">
  //       <p
  //         className="h-full text-green-700 font-extrabold flex justify-center flex-col-reverse items-center"
  //         title="Carregant..."
  //       >
  //         Carregant!
  //         <img src={loadingIco} alt="Loading..." />
  //       </p>
  //     </div>
  //   </div>
  // )

  return getApiCallGet?.data ? (
  <>
    <div className="w-full">
      <div className="flex flex-col">
        <StudentsCompGrid
          items={getApiCallGet.data}
          handleClick={handleClick}
        />
      </div>

      {getApiCallGet.data.length === 0 && (
        <div
          className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md mt-4"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-red-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Cap registre trobat.</p>
              <p className="text-sm">
                No hi ha registres disponibles. Pots afegir un nou alumne prement el botó{' '}
                <b>
                  <i>Crear Alumne</i>
                </b>
                !
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
) : (
  <div>
    <div className="h-full w-full flex justify-center items-center content-center">
      <p
        className="h-full text-green-700 font-extrabold flex justify-center flex-col-reverse items-center"
        title="Carregant..."
      >
        Carregant!
        <img src={loadingIco} alt="Loading..." />
      </p>
    </div>
  </div>
);

}

StudentsViewGrid.propTypes = {
  params: PropTypes.any
}
