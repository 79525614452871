// import PropTypes from 'prop-types'
// import {
//   useTable,
//   useFilters,
//   useSortBy,
//   usePagination,
//   useGlobalFilter
// } from 'react-table'
// import FilterUp from 'components/icons/FilterUp'
// import FilterDown from 'components/icons/FilterDown'
// import SchoolsTableBar from './SchoolsTableBar'
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function SchoolsTable({ data, columns, handleClick }) {
//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   )

//   const { pageIndex, pageSize } = state

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         {/* Tabla: */}
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           {/* Cabecera: */}
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getSortByToggleProps({ title: undefined })}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}

//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : (
//                         ''
//                       )}
//                     </div>

//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 {/* <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4 text-center">Festius</div>
//                 </th> */}
//               </tr>
//             ))}
//           </thead>

//           {/* Cuerpo: */}
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row)
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   <td className={`text-sm font-bold pl-4 py-4 w-2/12`}>
//                     {row.original.NOM}
//                   </td>

//                   <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
//                     {row.original.CODI}
//                   </td>

//                   <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
//                     {row.original.CENTRE}
//                   </td>

//                   <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
//                     {row.original.CCC}
//                   </td>

//                   <td className={`text-sm font-medium pl-4 py-4 w-2/12`}>
//                     {row.original.CODI_BECARI}
//                   </td>

//                   {/* <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         // handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td> */}
//                 </tr>
//               )
//             })}
//           </tbody>

//           {/* Pie: */}
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   )
// }

// SchoolsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func
// }


import PropTypes from 'prop-types';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table';
import FilterUp from 'components/icons/FilterUp';
import FilterDown from 'components/icons/FilterDown';
import SchoolsTableBar from './SchoolsTableBar';
import { FaCalendarAlt } from 'react-icons/fa';

export default function SchoolsTable({ data, columns, handleClick }) {
  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    setAllFilters,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-end w-full px-4">
        
        {/* Botón para borrar */}
        <button
          type="button"
          className="font-bold rounded-lg text-white bg-indigo-800 mb-4 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105"
          onClick={() => {
            setAllFilters([]);
            columns.forEach((column) => {
              localStorage.setItem(column.accessor, undefined);
              // localStorage.removeItem(column.id);
            });
          }}
        >
          Esborrar filtres
        </button>
      </div>
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          {/* Cabecera: */}
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getSortByToggleProps({ title: undefined })}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo: */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                    ${index % 2 === 0 && 'bg-indigo-50'}`}
                >
                  <td className="text-sm font-bold pl-4 py-4 w-2/12">
                    {row.original.NOM_SCHOOL}
                  </td>
                  <td className="text-sm font-medium pl-4 py-4 w-2/12">
                    {row.original.CODI_SCHOOL}
                  </td>
                  <td className="text-sm font-medium pl-4 py-4 w-2/12">
                    {row.original.CENTRE_SCHOOL}
                  </td>
                  <td className="text-sm font-medium pl-4 py-4 w-2/12">
                    {row.original.CCC_SCHOOL}
                  </td>
                  <td className="text-sm font-medium pl-4 py-4 w-2/12">
                    {row.original.CODI_BECARI_SCHOOL}
                  </td>
                </tr>
              );
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <SchoolsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={page.map((row) => row.original)} // Solo datos filtrados
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

SchoolsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
};
