import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Filtro personalizado para rango de fechas

export default function DateRangeColumnFilter({ column }) {
  const { setFilter, filterValue = [] } = column;
  const [localMinDate, setLocalMinDate] = useState(filterValue[0] || '');
  const [localMaxDate, setLocalMaxDate] = useState(filterValue[1] || '');

  // Sincroniza el estado local con el filtro global
  useEffect(() => {
    setLocalMinDate(filterValue[0] || '');
    setLocalMaxDate(filterValue[1] || '');
  }, [filterValue]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <input
          value={localMinDate}
          type="date"
          onChange={(e) => {
            const value = e.target.value;
            setLocalMinDate(value);
            setFilter([value, localMaxDate]);
          }}
          style={inputStyle}
          onClick={(e) => e.stopPropagation()}
        />
      </div>

      <div style={{ position: 'relative' }}>
        <input
          value={localMaxDate}
          type="date"
          onChange={(e) => {
            const value = e.target.value;
            setLocalMaxDate(value);
            setFilter([localMinDate, value]);
          }}
          style={inputStyle}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
}

const inputStyle = {
  color: 'rgb(156, 163, 175)',
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '2px',
  lineHeight: '1.25rem',
  fontSize: '0.875rem',
  paddingLeft: '0.25rem',
  height: '2rem',
  width: '100%',
};


DateRangeColumnFilter.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
    filterValue: PropTypes.array,
  }).isRequired,
};
